import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RoutingService {
  public prevUrl: string = null;
  public currentUrl: string = '/home';
  private previousUrl: BehaviorSubject<string> = new BehaviorSubject<string>(this.prevUrl);
  public previousUrl$: Observable<string> = this.previousUrl.asObservable();

  setPreviousUrl(previousUrl: string) {
    this.previousUrl.next(previousUrl);
  }

}
